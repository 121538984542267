<template>
  <div id="SixtyEightRoom">
    <HeroCarousel :heroCarouselData="heroCarouselData" />
    <div class="gradient"></div>
    <TicketPackages :ticketPackagesHeader="ticketPackagesHeader" :packages="packages" :ticketPackageImages="ticketPackageImages" />
    <!-- <SelectDate :dateCard="dateCard" dateCardTitle="1968 Room" /> -->
    <!-- <iframe src="https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=priva0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" frameborder="0" style="display:none"></iframe> -->
  </div>
</template>

<script>
import HeroCarousel from '@/components/patterns/HeroCarousel'
import TicketPackages from '@/components/patterns/TicketPackages'
import SelectDate from '@/components/patterns/SelectDate'
import { getPrivateHospitalityData } from "@/data/HeroCarousel/privateHospitalityData"
import { getTicketPackagesHeader } from "@/data/TicketPackages/privateHospitalityTicketPackagesData"
import { getOverlookDateCardData } from "@/data/DateSelect/overlookDateCardData"
import axios from "axios"

export default {
  name: "PrivateHospitality",
  components: {
    HeroCarousel,
    TicketPackages,
    SelectDate
  },
  data() {
    return {
      heroCarouselData: getPrivateHospitalityData(),
      // ticketPackagesHeader: getTicketPackagesHeader(),
      ticketPackagesHeader: {
        header: "<p>Experience the social atmosphere of a US&nbsp;Open premium lounge while enjoying the privacy of your own space.</p>"
      },
      dateCard: getOverlookDateCardData(),
      packages: null,
      ticketPackageImages: [
        "PrivateHospitality/private-hospitality_the-details.jpg",
        "PrivateHospitality/private-hospitality_the-cuisine-the-drinks.jpg",
        "PrivateHospitality/private-hospitality_the-view.jpg"
      ]
    }
  },
  mounted() {
    window.scrollTo(0,0)

    var axel = Math.random() + "";
    var a = axel * 10000000000000;
    var axel2 = Math.random() + "";
    var b = axel2 * 10000000000000;

    let floodlightTag = document.createElement('script')
    let floodlightTag2 = document.createElement('script')
    floodlightTag.setAttribute('src', 'https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=priva0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=' + a + '?')
    floodlightTag2.setAttribute('src', 'https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=priva00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=' + b + '?')
    document.body.appendChild(floodlightTag)
    document.body.appendChild(floodlightTag2)

    let pages = this.$store.state.epitch.ePitch.pages;
    // window.console.log('pages', pages)
    pages.forEach(page => {
      if (page.id === '6') {
        this.packages = JSON.parse(page.copy)
      }
    })

    if(this.$store.state.epitch.ePitch.mode === "live"){
      const purl = this.$store.state.epitch.ePitch.purl
      const epitchUrl = this.$store.state.epitch.url
      axios.post(`${epitchUrl}/epitchApi/EpitchLiteTracking.php`, {
          method:'page',
          purl:purl,
          presentationId: window.presentationId,
          sessionDbId: window.sessionDbId,
          section: 6
      })
    }

    let copy = this.$store.state.epitch.ePitch.pages
    if (typeof copy !== "undefined") {
      copy.forEach(page => {
        if (page.id === '6') {
          let description = JSON.parse(page.copy)
          let pageDescription = description.pageDescription
          if (pageDescription) {
            this.ticketPackagesHeader.header = pageDescription
          }
          // window.console.log('description', pageDescription)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>