export const getPrivateHospitalityData = () => {
  return [
    {
      carouselImages: [
        "PrivateHospitality/Carousel/private-hospitality_gallery_0.jpg",
        "PrivateHospitality/Carousel/private-hospitality_gallery_1.jpg",
        "PrivateHospitality/Carousel/private-hospitality_gallery_2.jpg",
        "PrivateHospitality/Carousel/private-hospitality_gallery_3.jpg",
        "PrivateHospitality/Carousel/private-hospitality_gallery_4.jpg",
        "PrivateHospitality/Carousel/private-hospitality_gallery_5.jpg",
        "PrivateHospitality/Carousel/private-hospitality_gallery_6.jpg",
        "PrivateHospitality/Carousel/private-hospitality_gallery_7.jpg",
      ],
      title: "Private Hospitality Space",
      // subTitle: '',
      bottomLinks: [
        {
          title: "gallery",
          link: "modal",
          icon: "gallery",
        },
        // {
        //   title: "appointment",
        //   link: "appointment",
        //   icon: "appointment",
        // },
      ],
    },
  ];
};
